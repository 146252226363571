<template>
    <div class="popAlert">
        <div class="bg"></div>
        <div class="container" :class="{delete:type=='delete',createExh:type=='createExh'}">
            <img class="cross btn" @click="closeAlert" src="@/assets/img/icon/cross_black.svg" alt="">
            <h3 class="wd" :class="{wd230:type=='editAccount'||type=='deleteCudModel'}" v-if="title!=''">{{title}}</h3>
            <p v-if="ptext" class="wd">{{ptext}}</p>
            <div class="inputName" :class="{error:nameValid==false}" v-if="type=='createExh'">
                    <input type="text" v-model="name" placeholder="Enter your block’s name" @keyup="validName">
                    <span>Please enter block’s name</span>
            </div>
            <div class="btnWrap">
                <button class="cancel" v-if="cancelTxt!=''" @click="cancelAlert">{{cancelTxt}}</button>
                <button class="submit" v-if="submitTxt!=''" @click="confirm">{{submitTxt}}</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data:()=>({
        name:'',
        nameValid:null
    }),
    methods:{
        closeAlert(){
            this.$emit('closeAlert');
        },
        cancelAlert(){
            this.closeAlert();
            this.$emit('closeAlert',this.type);
        },
        confirm(){
            if(this.type=='createExh'){
                if(this.nameValid){
                    this.$emit('confirm','createExh',this.name);
                    this.nameValid = null;
                    this.name = '';
                }else{
                    this.nameValid = false;
                }
            }else if(this.type=='upgrade'){

            }else{
                this.$emit('confirm','editAccount');
            }
            this.closeAlert();

        },
        validName(){
            if(this.name=='')this.nameValid = false;
            else this.nameValid = true;
        }
    },
    props:{
        type:{
            type: String,
            default: 'delete',
        }
    },
    computed:{
        title(){
            if(this.type=='delete')
                return 'Do you want to delete this block forever?' 
            else if(this.type=='createExh')
                return 'Name your block first'
            else if(this.type=='editAccount')
                return 'Your changes have not been saved yet'
            else if(this.type=='deleteCudModel')
                return 'Delete the model ?'
            else if(this.type=='upgrade')
                return 'Our priemium service is coming soon!'
            else
                return ''
        },
        ptext(){
            if(this.type=='delete')
                return '' 
            else if(this.type=='createExh')
                return 'You can change the name in the editor later'
            else
                return ''
        },
        cancelTxt(){
            if(this.type=='delete'||this.type=='deleteCudModel')
                return 'Cancel' 
            else if(this.type=='createExh')
                return ''
            else if(this.type=='editAccount')
                return 'Leave'
            else
                return ''
        },
        submitTxt(){
            if(this.type=='delete'||this.type=='deleteCudModel')
                return 'Delete' 
            else if(this.type=='createExh')
                return 'Confirm and Start'
            else if(this.type=='editAccount')
                return 'Save'
            else if(this.type=='upgrade')
                return 'OK'
            else
                return ''
        }
    }  
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.popAlert{
    width:100%;
    height:100vh;
    position:fixed;    
    z-index: 9;
    .bg{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: rgba(0,0,0,.5);
    }
    .container{
        background-color: #EFEFEF;
        width:346px;
        border-radius:7px;
        position: absolute;
        top:50%;
        left:50%;
        margin:0 auto;
        transform:translate(-50%,-50%);
        padding: 3px 3px 30px;
        .cross{
            display: block;
            margin: 0 0 0 auto;
        }
        .wd{
            width:310px;
        }
        .wd230{
            width:230px;
            margin-bottom: 5%;
        }
        h3{
            font-size: 16px;
            text-align: center;
            margin:0 auto 20px;
            font-weight: 500;
            line-height: 1.5;
        }
        p{
            font-size: 12px;
        }
        .btnWrap{
            button{
                color:#fff;
                @include blockShadow;
                border-radius: 4px;
                font-size: 14px;
                line-height: 2.4;
                width:100px;
                
                &.cancel{
                    color:$purple;
                    border:1px $purple solid;
                    margin-right:7px;
                }
                &.submit{
                    margin-left: 7px;
                }
            }
        }
        &.delete{
            .cross{
                margin-bottom:20px;
            }
            h3{
                margin:0 auto 35px;
            } 
        }
        &.createExh{
            h3{
                margin:0 auto 15px;
            }
            p{
                margin: 0 auto 20px;
            }
            .inputName{
                position:relative;
                display: inline-block;
                margin-bottom: 30px;
                &.error{
                    input{
                        border-color:#FB4A93;
                    }
                    span{
                        color:#FB4A93;
                        font-size: 12px;
                        position:absolute;
                        bottom: -18px;
                        left: 0;
                        display: block;
                        &:before{
                            content:'';
                            background-image: url(~@/assets/img/icon/info_pink.svg);
                            width:10px;
                            padding-bottom:10px;
                            display: inline-block;      
                            margin-right: 5px;     
                            background-size: cover;             
                        }
                    }

                }
            }
            input{
                width: 226px;
                font-size: 12px;
                line-height: 2.2;
                border-radius: 2px;
                display: block;
                margin: 0 auto;
                padding: 0 10px;
            }
            span{
                display: none;
            }
            button{
                &.submit{
                    width: 226px;
                    margin:0;
                }
            }
        }
    }
}
</style>